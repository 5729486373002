@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DotGothic16&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Indie+Flower&family=Shippori+Mincho:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@1,300&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

html {
  --scrollbarBG: #cfcfcf;
  --thumbBG: #0b5750;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}
body::-webkit-scrollbar {
  width: 0px;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 10px;
  padding: 0px;
  /* padding: 5px; */
  border: 3px solid var(--scrollbarBG);
  border: none;
}
body {
  margin: 0;
  font-family: "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  overflow-x: hidden;
  box-sizing: border-box;
}

hr {
  border: 0;
  height: 1px;
  max-width: 1200px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Shippori Mincho", serif;
}
